import React from "react";
import Color from "color";
export interface LegendItem {
  category: string;
  fill: string;
  gradientId?: string;
}
export interface LegendProps {
  items: LegendItem[];
}
export const Legend = ({
  items
}: LegendProps) => {
  if (!items.length) return <div />;
  return <div className="relative max-w-[calc(100%-120px)] overflow-x-auto overflow-y-hidden [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden" data-sentry-component="Legend" data-sentry-source-file="Legend.tsx">
      <div className="flex items-center gap-12 px-4">
        {items.map((item, index) => <div key={`legend-${index}`} className="flex shrink-0 items-center gap-2">
            <svg width="20" height="20">
              <rect width="20" height="20" rx="2" fill={item.gradientId ? `url(#${item.gradientId})` : item.fill} stroke={Color(item.fill).darken(0.6).hex()} strokeWidth="1" />
            </svg>
            <span className="whitespace-nowrap text-sm text-text-body">
              {item.category}
            </span>
          </div>)}
      </div>
    </div>;
};