import React from "react";
import { Heading } from "@/edges/ui/Heading";
import { Skeleton } from "@/edges/ui/Skeleton";
interface CardProps {
  heading?: string;
  upperRightText?: React.ReactNode;
  withPadding?: boolean;
  isGhost?: boolean;
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}
export const Card: React.FC<CardProps> = ({
  heading,
  upperRightText,
  withPadding = true,
  isGhost = false,
  children,
  className = "",
  isLoading
}) => {
  const baseClasses = "w-full rounded md:border-x border-y bg-background-surface ";
  const paddingClasses = withPadding ? "p-6" : "";
  const ghostClasses = isGhost ? "md:border-x-0 border-y-0 shadow-none p-0" : "shadow-sm";
  const cardClasses = `${baseClasses} ${paddingClasses} ${ghostClasses} ${className}`.trim();
  return <div className={cardClasses} data-testid="card" data-sentry-component="Card" data-sentry-source-file="Card.tsx">
      <>
        {heading && <div className="mb-4 h-[28px] min-h-[28px]">
            {isLoading ? <Skeleton width={100} height={16} data-testid="skeleton" /> : <div className="flex justify-between">
                <Heading size="xs">{heading}</Heading>
                {upperRightText && <span className="text-sm text-textFaint">
                    {upperRightText}
                  </span>}
              </div>}
          </div>}
        {children}
      </>
    </div>;
};