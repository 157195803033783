import React, { useState, useRef } from "react";
import { ChevronDown } from "lucide-react";
import { Select as AriaSelect, SelectProps as AriaSelectProps, ListBox, SelectValue, ValidationResult, Key } from "react-aria-components";
import { Description, FieldError, Label } from "@/edges/ui/Field";
import { Button } from "@/edges/ui/Button";
import { DropdownSection, DropdownSectionProps } from "@/edges/ui/ListBox";
import { Popover } from "@/edges/ui/Popover";
import { composeTailwindRenderProps } from "@/edges/utils";

// Replace tv() with a regular function
type SelectVariants = {
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  isOpen?: boolean;
  isInvalid?: boolean;
};
function getSelectClassName({
  isDisabled,
  size = "md",
  isOpen,
  isInvalid
}: SelectVariants = {}) {
  const baseClasses = ["flex items-center text-start gap-4 w-full cursor-pointer bg-background-input text-text-body shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] rounded-sm pl-3 pr-3 py-2 min-w-[200px] transition outline outline-2"];
  const sizeClasses = {
    sm: "h-[theme(controlElementSize.sm)] text-controlElementSm",
    md: "h-[theme(controlElementSize.md)] text-controlElementMd",
    lg: "h-[theme(controlElementSize.lg)] text-controlElementLg",
    xl: "h-[theme(controlElementSize.xl)] text-controlElementXl"
  }[size];
  const stateClasses = isDisabled ? "opacity-50 cursor-not-allowed outline-border-input forced-colors:outline-[GrayText]" : isInvalid ? "outline-feedback-error-border group-invalid:outline-feedback-error-border forced-colors:group-invalid:outline-[Mark]" : isOpen ? "outline-border-focus dark:outline-zinc-300 forced-colors:outline-[Highlight]" : "outline-border-input";
  return [...baseClasses, sizeClasses, stateClasses].filter(Boolean).join(" ");
}
function getSelectValueClassName(size: "sm" | "md" | "lg" | "xl" = "md") {
  const textSizeClass = {
    sm: "text-controlElementSm",
    md: "text-controlElementMd",
    lg: "text-controlElementLg",
    xl: "text-controlElementXl"
  }[size];
  return `flex-1 text-start data-[placeholder]:text-text-placeholder ${textSizeClass}`;
}
export type SelectVariantType = SelectVariants;
export interface SelectProps<T extends object> extends Omit<AriaSelectProps<T>, "children" | "onSelectionChange"> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items?: Iterable<T>;
  size: "sm" | "md" | "lg" | "xl";
  children: React.ReactNode | ((item: T) => React.ReactNode);
  selectedKey?: Key;
  defaultSelectedKey?: Key;
  onSelectionChange?: (key: Key) => void;
  showErrors?: boolean;
  tooltip?: string;
  isRequired?: boolean;
  validationResult?: ValidationResult;
}
export function Select<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  size,
  selectedKey: controlledSelectedKey,
  defaultSelectedKey,
  onSelectionChange,
  placeholder,
  showErrors = false,
  tooltip,
  isRequired,
  ...props
}: SelectProps<T>) {
  const [internalSelectedKey, setInternalSelectedKey] = useState<Key | null>(null);
  const selectedKey = controlledSelectedKey ?? internalSelectedKey ?? defaultSelectedKey;
  const handleSelectionChange = (key: Key) => {
    if (onSelectionChange) {
      onSelectionChange(key);
    } else {
      setInternalSelectedKey(key); // Update internal state if uncontrolled
    }
  };
  const selectRef = useRef<HTMLDivElement>(null);
  return <AriaSelect {...props} selectedKey={selectedKey} defaultSelectedKey={defaultSelectedKey} onSelectionChange={handleSelectionChange} placeholder={placeholder} className={composeTailwindRenderProps(props.className, "group flex w-full flex-col")} ref={selectRef} onOpenChange={isOpen => {
    if (!isOpen) {
      selectRef.current?.blur();
    }
  }} data-sentry-element="AriaSelect" data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      {state => <>
          {label && <Label size={size} tooltip={tooltip} isRequired={isRequired} className="mb-2">
              {label}
            </Label>}
          <Button variant="unstyled" className={getSelectClassName({
        isDisabled: props.isDisabled,
        isInvalid: !!errorMessage,
        size,
        isOpen: state.isOpen
      })}>
            <SelectValue className={getSelectValueClassName(size)} />
            <ChevronDown aria-hidden className="h-4 w-4 text-text-body group-disabled:text-gray-200 forced-colors:text-[ButtonText]" />
          </Button>
          {description && <Description size={size} className="mt-3">
              {description}
            </Description>}
          {showErrors && <FieldError size={size} className="mt-3 text-feedback-error-text">
              {typeof errorMessage === "function" && props.validationResult ? errorMessage(props.validationResult) : errorMessage}
            </FieldError>}
          <Popover className="min-w-[--trigger-width] bg-background-input">
            <ListBox items={items} className="max-h-[inherit] overflow-auto bg-background-input p-1 outline-0 [clip-path:inset(0_0_0_0_round_.75rem)]">
              {children}
            </ListBox>
          </Popover>
        </>}
    </AriaSelect>;
}
export function SelectSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} data-sentry-element="DropdownSection" data-sentry-component="SelectSection" data-sentry-source-file="Select.tsx" />;
}