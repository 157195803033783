import { ListBoxItem as AriaListBoxItem, ListBoxItemProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";
const itemHeightStylesObject = {
  sm: "py-1",
  md: "py-2",
  lg: "py-3",
  xl: "py-4"
};
const textSizeStylesObject = {
  sm: "text-controlElementSm",
  md: "text-controlElementMd",
  lg: "text-controlElementLg",
  xl: "text-controlElementXl"
};
interface ExtendedListBoxItemProps extends Omit<ListBoxItemProps, "className"> {
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
}
export function ListBoxItem({
  size = "md",
  className,
  ...props
}: ExtendedListBoxItemProps) {
  const baseClassName = "flex cursor-default items-center gap-2 rounded-sm px-3 text-text-body outline-none data-[focused]:bg-background-hover data-[selected]:bg-background-hover";
  return <AriaListBoxItem {...props} className={twMerge(baseClassName, itemHeightStylesObject[size], textSizeStylesObject[size], className)} data-sentry-element="AriaListBoxItem" data-sentry-component="ListBoxItem" data-sentry-source-file="ListBoxItem.tsx" />;
}