import { extent, max, min } from "d3-array";
import { scaleLinear, scaleTime } from "@visx/scale";
import {
  tokW,
  tokWh,
  toAmps,
  toTemperature,
  TemperatureUnitSymbol,
} from "@/utils";
import { defaultMargin } from "../../types/ChartProps";
import { YFormatType } from "@/edges/context/ChartContext";
import { startOfHour } from "date-fns";
import { WindowSize } from "@/@codegen/supergraph";

export type { YFormatType };

export interface BaseDataPoint {
  xValue: Date;
  yValue: number;
}

export interface YFormatSettings {
  format: (value: number) => string;
  min?: number;
  max?: number;
  tickInterval?: number;
  tickFormat?: (value: number) => string;
}

export function getYFormatSettings(
  formatType: YFormatType,
  currencySymbol = "$",
  preferredTemperatureUnit?: TemperatureUnitSymbol,
): YFormatSettings {
  const safeFormat = (
    value: number | null | undefined,
    formatter: (v: number) => string,
  ): string => {
    if (value == null) return "-";
    return formatter(value);
  };

  switch (formatType) {
    case "number":
      return {
        format: (value) => safeFormat(value, (v) => v.toLocaleString()),
        tickFormat: (value) => safeFormat(value, (v) => v.toLocaleString()),
      };
    case "percent":
      return {
        format: (value) => safeFormat(value, (v) => `${v.toFixed(1)}%`),
        tickFormat: (value) => safeFormat(value, (v) => `${v.toFixed(0)}%`),
        min: 0,
        max: 100,
        tickInterval: 20,
      };
    case "kWh":
      return {
        format: (value) => safeFormat(value, (v) => `${tokWh(v)}`),
        tickFormat: (value) =>
          safeFormat(value, (v) => `${Math.round(v / 1000).toFixed(1)}`),
      };
    case "kW":
      return {
        format: (value) => safeFormat(value, (v) => `${tokW(v)}`),
        tickFormat: (value) =>
          safeFormat(value, (v) => `${Math.round(v / 1000).toFixed(1)}`),
      };
    case "amperes":
      return {
        format: (value) => safeFormat(value, (v) => `${toAmps(v)}`),
        tickFormat: (value) => safeFormat(value, (v) => `${toAmps(v)}`),
      };
    case "temperature":
      return {
        format: (value) =>
          safeFormat(
            value,
            (v) => `${toTemperature(v, preferredTemperatureUnit)}`,
          ),
        tickFormat: (value) => safeFormat(value, (v) => `${v.toFixed(0)}`),
      };
    case "percentageChange":
      return {
        format: (value) =>
          safeFormat(
            value,
            (v) => `${v > 0 ? "+" : ""}${(v * 100).toFixed(1)}%`,
          ),
        tickFormat: (value) =>
          safeFormat(
            value,
            (v) => `${v > 0 ? "+" : ""}${(v * 100).toFixed(0)}%`,
          ),
        min: -100,
        max: 100,
      };
    case "decimal":
      return {
        format: (value) => safeFormat(value, (v) => v.toFixed(2)),
        tickFormat: (value) => safeFormat(value, (v) => v.toFixed(1)),
      };
    case "currency":
      return {
        format: (value) =>
          safeFormat(value, (v) => `${currencySymbol}${v.toLocaleString()}`),
        tickFormat: (value) =>
          safeFormat(value, (v) => `${currencySymbol}${v.toLocaleString()}`),
      };
    case "scientific":
      return {
        format: (value) => safeFormat(value, (v) => v.toExponential(2)),
        tickFormat: (value) => safeFormat(value, (v) => v.toExponential(1)),
      };
    case "integer":
      return {
        format: (value) =>
          safeFormat(value, (v) => Math.round(v).toLocaleString()),
        tickFormat: (value) =>
          safeFormat(value, (v) => Math.round(v).toLocaleString()),
      };
    case "logarithmic":
      return {
        format: (value) => safeFormat(value, (v) => v.toExponential(1)),
        tickFormat: (value) => safeFormat(value, (v) => v.toExponential(0)),
        min: 1,
        tickInterval: 10,
      };
    case "timeDuration":
      return {
        format: (value) =>
          safeFormat(
            value,
            (v) =>
              `${Math.floor(v / 60)}:${(v % 60).toString().padStart(2, "0")} min`,
          ),
        tickFormat: (value) =>
          safeFormat(value, (v) => `${Math.floor(v / 60)}m`),
        min: 0,
      };
    case "compact":
      return {
        format: (value) =>
          safeFormat(value, (v) =>
            new Intl.NumberFormat("en", { notation: "compact" }).format(v),
          ),
        tickFormat: (value) =>
          safeFormat(value, (v) =>
            new Intl.NumberFormat("en", { notation: "compact" }).format(v),
          ),
      };
    case "si":
      return {
        format: (value) =>
          safeFormat(value, (v) =>
            new Intl.NumberFormat("en", {
              notation: "compact",
              maximumFractionDigits: 2,
            }).format(v),
          ),
        tickFormat: (value) =>
          safeFormat(value, (v) =>
            new Intl.NumberFormat("en", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(v),
          ),
      };
    case "bytes":
      return {
        format: (value) =>
          safeFormat(value, (v) => {
            const units = ["B", "KB", "MB", "GB", "TB"];
            let unitIndex = 0;
            let val = v;
            while (val >= 1024 && unitIndex < units.length - 1) {
              val /= 1024;
              unitIndex++;
            }
            return `${val.toFixed(1)} ${units[unitIndex]}`;
          }),
        tickFormat: (value) =>
          safeFormat(value, (v) => {
            const units = ["B", "KB", "MB", "GB", "TB"];
            let unitIndex = 0;
            let val = v;
            while (val >= 1024 && unitIndex < units.length - 1) {
              val /= 1024;
              unitIndex++;
            }
            return `${val.toFixed(0)} ${units[unitIndex]}`;
          }),
        min: 0,
      };
    case "rate":
      return {
        format: (value) => safeFormat(value, (v) => `${v.toFixed(1)} req/sec`),
        tickFormat: (value) => safeFormat(value, (v) => `${v.toFixed(0)}/s`),
      };
    case "ordinal":
      return {
        format: (value) =>
          safeFormat(value, (v) => {
            const suffixes = ["th", "st", "nd", "rd"];
            const v2 = v % 100;
            return (
              v + (suffixes[(v2 - 20) % 10] || suffixes[v2] || suffixes[0])
            );
          }),
        tickFormat: (value) => safeFormat(value, (v) => v.toString()),
        min: 1,
      };
    case "date":
      return {
        format: (value) =>
          safeFormat(value, (v) =>
            new Intl.DateTimeFormat("en", { dateStyle: "medium" }).format(
              new Date(v),
            ),
          ),
        tickFormat: (value) =>
          safeFormat(value, (v) =>
            new Intl.DateTimeFormat("en", { dateStyle: "short" }).format(
              new Date(v),
            ),
          ),
        min: 0,
      };
    case "largeCurrency":
      return {
        format: (value) =>
          safeFormat(value, (v) => `${currencySymbol}${(v / 1e6).toFixed(2)}M`),
        tickFormat: (value) =>
          safeFormat(value, (v) => `${currencySymbol}${(v / 1e6).toFixed(0)}M`),
      };
    case "coordinates":
      return {
        format: (value) =>
          safeFormat(
            value,
            (v) => `${Math.abs(v).toFixed(3)}° ${v >= 0 ? "N" : "S"}`,
          ),
        tickFormat: (value) =>
          safeFormat(
            value,
            (v) => `${Math.abs(v).toFixed(1)}° ${v >= 0 ? "N" : "S"}`,
          ),
        min: -90,
        max: 90,
      };
    case "ranked":
      return {
        format: (value) => safeFormat(value, (v) => `Rank: ${v}`),
        tickFormat: (value) => safeFormat(value, (v) => v.toString()),
        min: 1,
      };
    default:
      return {
        format: (value) => safeFormat(value, (v) => v.toLocaleString()),
        tickFormat: (value) => safeFormat(value, (v) => v.toLocaleString()),
      };
  }
}

export const createXScale = (data: any[], width: number) => {
  const dateExtent = extent(data, (d) => d.xValue) as [Date, Date];
  return scaleTime({
    range: [defaultMargin.left, width - defaultMargin.right],
    domain: dateExtent,
    nice: true,
  });
};

export const createYScale = (
  data: any[],
  height: number,
  formatType: YFormatType,
) => {
  const yFormatSettings = getYFormatSettings(formatType);
  const dataYMin = min(data, (d) => d.yValue) || 0;
  const dataYMax = max(data, (d) => d.yValue) || 0;
  const yMin =
    yFormatSettings.min !== undefined
      ? yFormatSettings.min
      : dataYMin >= 0
        ? 0 // 0 if all positive values, min is 0
        : dataYMin * 1.1; // else scale the negative values by 10% to make room for the y-axis values
  const yMax =
    yFormatSettings.max !== undefined
      ? yFormatSettings.max
      : dataYMax <= 0
        ? 0 // If all values are negative, max is 0
        : dataYMax * 1.1; // else scale the positive values by 10% to make room for the y-axis values
  return scaleLinear({
    range: [height - defaultMargin.bottom, defaultMargin.top],
    domain: [yMin, yMax],
    nice: true,
  });
};

/**
 * Calculates cumulative totals for time series data within a given date range and window size
 */
interface TimeSeriesItem {
  [key: string]: any;
}

export function calculateCumulativeTotals<T extends TimeSeriesItem>({
  items,
  dateRange,
  windowSize,
  getTimestamp = (item: T) => item.timestamp,
  getWindowStart = (date: Date) => startOfHour(date),
  getTimeStepMs = () => 60 * 60 * 1000, // default 1 hour
}: {
  items: T[];
  dateRange: { after: Date; before: Date };
  windowSize?: WindowSize;
  getTimestamp: (item: T) => Date;
  getWindowStart: (date: Date, windowSize?: WindowSize) => Date;
  getTimeStepMs: (windowSize?: WindowSize) => number;
}): BaseDataPoint[] {
  if (!items.length) return [];

  const timeStep = getTimeStepMs(windowSize);
  const startTime = getWindowStart(dateRange.after, windowSize).getTime();
  const endTime = getWindowStart(dateRange.before, windowSize).getTime();

  // Sort items by timestamp
  const sortedItems = [...items].sort(
    (a, b) => getTimestamp(a).getTime() - getTimestamp(b).getTime(),
  );

  const points: BaseDataPoint[] = [];
  let currentTimestamp = startTime;

  // Generate points for each window
  while (currentTimestamp <= endTime) {
    const currentTime = new Date(currentTimestamp);
    const cumulativeCount = sortedItems.filter(
      (item) => getTimestamp(item) <= currentTime,
    ).length;

    points.push({
      xValue: currentTime,
      yValue: cumulativeCount,
    });

    currentTimestamp += timeStep;
  }

  // Ensure we have a point exactly at the end time
  const lastPoint = points[points.length - 1];
  if (!lastPoint || lastPoint.xValue.getTime() !== endTime) {
    const finalCount = sortedItems.filter(
      (item) => getTimestamp(item) <= new Date(endTime),
    ).length;

    // Only add the end point if it's different from the last point
    // or if it's the only point
    if (!lastPoint || lastPoint.yValue !== finalCount) {
      points.push({
        xValue: new Date(endTime),
        yValue: finalCount,
      });
    }
  }

  return points;
}
